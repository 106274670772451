import Vue from 'vue/dist/vue.esm'

document.addEventListener('DOMContentLoaded', () => {
  let rootElement = document.querySelector('#sessions');
  if (!rootElement) { return }

  new Vue({
    el: rootElement,
    data: {
      showPassword: false
    },
    methods: {
      togglePassword() {
        this.showPassword = !this.showPassword
        if (this.showPassword) {
          $("#pass").attr('type', 'text');
        } else {
          $("#pass").attr('type', 'password');
        }
      }
    }
  })
});